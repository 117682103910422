
import EditFileDownloadTemplate from "~/graphql/Template/RenameTemplate.gql";
import Templates from "~/graphql/Template/Templates.gql";

export default {
  props: {
    templateName: {
      type: String,
      required: true,
    },
    template: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      templateNewName: this.templateName,
    };
  },

  computed: {
    isFormValid() {
      return !!this.templateName;
    },
  },

  methods: {
    renameTemplate() {
      this.$apollo.mutate({
        mutation: EditFileDownloadTemplate,
        variables: {
          input: {
            id: this.$route.params.id,
            name: this.templateNewName,
            template: this.template,
          },
        },
        refetchQueries: [{ query: Templates }],
      });
      this.$emit("close");
    },
  },
};
