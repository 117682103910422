
import Draggable from "vuedraggable";
import TemplateDocument from "./TemplateDocument.vue";
import { convertToTemplateDocumentItem } from "../../helpers/template-helpers";

export default {
  components: {
    Draggable,
    TemplateDocument,
  },

  props: {
    list: {
      type: Array,
      required: true,
    },

    groupName: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },
  },

  methods: {
    convertToTemplateDocumentItem,
  },
};
