
import Draggable from "vuedraggable";
import NamingVariable from "./NamingVariable.vue";
import CustomVariable from "./CustomVariable.vue";
import { addUUID } from "../../helpers/template-helpers.js";
import AvailableSeperators from "./AvailableSeperators.vue";

export default {
  components: {
    Draggable,
    NamingVariable,
    CustomVariable,
    AvailableSeperators,
  },

  props: {
    newItemKeys: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      modelValue: [],
    };
  },

  computed: {
    items: {
      get: function () {
        return this.newItemKeys;
      },
      set: function (val) {
        return this.$emit("update:newItemKeys", val);
      },
    },
  },

  methods: {
    addUUID,

    updateCustomKey(key, index) {
      this.items.splice(index, 1, key);
    },
  },
};
