
import folder from "../../../../assets/icons/folder.vue";
import pdf from "../../../../assets/icons/pdf.vue";
import png from "../../../../assets/icons/png.vue";
import xlsx from "../../../../assets/icons/xlsx.vue";

export default {
  components: {
    folder,
    pdf,
    png,
    xlsx,
  },

  props: {
    variant: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      icons: {
        folder,
        pdf,
        png,
        xlsx,
      },
    };
  },
};
