import { render, staticRenderFns } from "./RenameTemplateModal.vue?vue&type=template&id=3868dd3c&scoped=true"
import script from "./RenameTemplateModal.vue?vue&type=script&lang=js"
export * from "./RenameTemplateModal.vue?vue&type=script&lang=js"
import style0 from "./RenameTemplateModal.vue?vue&type=style&index=0&id=3868dd3c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3868dd3c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,CompositionSpacer: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,TextField: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/TextField/TextField.vue').default,KeyValuePair: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default,PushButton: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/PushButton/PushButton.vue').default,Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,Modal: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Modal/Modal.vue').default})
