
import Draggable from "vuedraggable";
import TemplateFolder from "./TemplateFolder.vue";
import TemplateGroup from "./TemplateGroup.vue";
import TemplateDocument from "./TemplateDocument.vue";
import { ALL_DOCUMENTS } from "../../template-data";
import NamingVariableRenderer from "../NamingComponents/NamingVariableRenderer.vue";

export default {
  name: "TemplateRenderer",
  components: {
    Draggable,
    TemplateFolder,
    TemplateGroup,
    TemplateDocument,
    NamingVariableRenderer,
  },

  props: {
    acceptsFromGroups: {
      type: Array,
      required: true,
    },

    modelValue: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      allDocuments: ALL_DOCUMENTS,
      openNamingModal: false,
      model: this.modelValue,
    };
  },

  computed: {
    isExampleViewEnabled: {
      get: function () {
        return this.$store.state.templateBuilder.isExampleViewEnabled;
      },
    },

    template: {
      get: function () {
        return this.model;
      },
      set: function (val) {
        // Note Morten: Drop events are not emitted when the user drops an to trash. This ensure that dragging is set to false when the user drops an item to trash.
        this.$emit("change")
        this.$store.commit('templateBuilder/setIsDraggingDocument', false)

        return this.$emit("update:modelValue", val);
      },
    },
  },

  methods: {
    findDocumentByTemplateDocumentItem(element) {
      return this.allDocuments.find((x) => x.id === element.id);
    },

    change(event) {
      this.$emit("change")
    }
  },
};
