
import { getPropertyTypeString } from "./helpers/template-helpers.js";
import RenameTemplateModal from "./components/RenameTemplateModal.vue";
import DeleteTemplateModal from "./components/DeleteTemplateModal.vue";
import HelperModal from "./components/HelperModal.vue";
import AvailableDocuments from "./components/Templates/AvailableDocuments.vue";
import TemplateRenderer from "./components/Templates/TemplateRenderer.vue";
import DraggableTrash from "./components/Templates/DraggableTrash.vue";
import EditNamingKeysModal from "./components/EditNamingKeysModal.vue";
import FileDownloadTemplateById from "~/graphql/Template/TemplateById.gql";
import EditFileDownloadTemplate from "~/graphql/Template/EditTemplate.gql";
import ErrorModal from "./components/ErrorModal.vue";

export default {
  components: {
    RenameTemplateModal,
    DeleteTemplateModal,
    HelperModal,
    AvailableDocuments,
    TemplateRenderer,
    DraggableTrash,
    EditNamingKeysModal,
    ErrorModal,
  },
  apollo: {
    fileDownloadTemplateById: {
      query: FileDownloadTemplateById,
      variables: function () {
        return {
          fileDownloadTemplateByIdId: this.$route.params.id,
        };
      },

      update: function (data) {
        return structuredClone(data.fileDownloadTemplateById);
      },
    },
  },
  data() {
    return {
      renameModal: false,
      deleteModal: false,
      errorModal: false,
      key: 0,
    };
  },

  watch: {
    fileDownloadTemplateById: {
      handler: function (val) {
        this.key++;
      },
      deep: true,
    },
  },

  computed: {
    loading() {
      return this.$apollo.queries.fileDownloadTemplateById.loading;
    },

    isExampleViewEnabled: {
      get: function () {
        return this.$store.state.templateBuilder.isExampleViewEnabled;
      },
      set: function (val) {
        return this.$store.commit("templateBuilder/setIsExampleViewEnabled", val);
      },
    },

    hasEdited: {
      get: function () {
        return this.$store.state.templateBuilder.hasEdited;
      },
    },

    isDraggingDocument: {
      get: function () {
        return this.$store.state.templateBuilder.isDraggingDocument;
      },
    },
  },


  beforeRouteLeave(to, from, next) {
    const answer = this.hasEdited ? window.confirm("Er du sikker på du vil forlade skabelonen? Du har ændringer der ikke er gemt!") : true;
    if (answer) {
      next();
    } else {
      next(false);
    }
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", function (e) {
      e.preventDefault();
      e.returnValue = "";
    });
    this.$store.commit("templateBuilder/setHasEdited", false);
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => {
      if (this.hasEdited) {
        e.preventDefault();
        e.returnValue = "";
      }
    });
  },
  methods: {
    getPropertyTypeString,

    propertyTypeStringTranslate() {
      const getPropertyType = getPropertyTypeString(this.fileDownloadTemplateById.propertyType);
      if (getPropertyType === "Hovedejendom") {
        return this.$t("TEMPLATE_BUILDER_MAIN_PROPERTY_RENDERER");
      } else if (getPropertyType == "Hovedejendom opdelt i ejerlejligheder") {
        return this.$t("TEMPLATE_BUILDER_MAIN_PROPERTY_DIVIDED_IN_CONDOS_RENDERER");
      } else {
        return this.$t("TEMPLATE_BUILDER_CONDO_RENDERER");
      }
    },

    renameModalToggle() {
      this.renameModal = !this.renameModal;
    },

    deleteModalToggle() {
      this.deleteModal = !this.deleteModal;
    },

    errorModalToggle() {
      this.errorModal = !this.errorModal;
    },

    saveTemplate() {
      this.$apollo
        .mutate({
          mutation: EditFileDownloadTemplate,
          variables: {
            input: {
              id: this.$route.params.id,
              template: this.fileDownloadTemplateById.template,
            },
          },
          refetchQueries: [{ query: FileDownloadTemplateById, variables: { fileDownloadTemplateByIdId: this.$route.params.id } }],
        })
        .then((result) => {
          if (result.data.editFileDownloadTemplate) {
            this.$store.commit("templateBuilder/setHasEdited", false);
          }
        })
        .catch((error) => {
          console.error("All documents must be named", error);
          this.errorModal = true;
        });
    },

    goToIndex() {
      this.$router.push({
        name: "template-builder",
      });
    },

    handleChange() {
      return this.$store.commit("templateBuilder/setHasEdited", true);
    },

    setDate() {
      const dateFromDb = this.fileDownloadTemplateById.updatedAt;
      const date = new Date(dateFromDb).toLocaleString("da-DA");
      const dateFormatted = date.split(" ")[0];
      return dateFormatted;
    },

    addKeys(keys, id, separator) {
      let found;

      for (let i = 0; i < this.fileDownloadTemplateById.template.length; i++) {
        found = this.findId(id, this.fileDownloadTemplateById.template[i]);

        if (found) {
          break;
        }
      }

      this.$store.commit("templateBuilder/setKeys", keys);
      this.$store.commit("templateBuilder/closeNamingModal");
      this.$store.commit("templateBuilder/setHasEdited", true);
      this.$store.commit("templateBuilder/setSeparator", separator);
      found.keys = this.$store.getters["templateBuilder/getSetKeys"];
      found.separator = this.$store.getters["templateBuilder/getSeparator"];
    },

    findId(id, template) {
      var i, currentChild, result;

      if (id == template.uuid) {
        return template;
      } else if (template.children == undefined) {
        return undefined;
      } else {
        for (i = 0; i < template.children.length; i += 1) {
          currentChild = template.children[i];

          result = this.findId(id, currentChild);

          if (result !== undefined) {
            return result;
          }
        }

        return undefined;
      }
    },
  },
};
