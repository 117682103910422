
import Draggable from "vuedraggable";

export default {
  components: {
    Draggable,
  },

  props: {
    groupName: {
      type: String,
      required: true,
    },

    colorBackground: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      trash: [],
    };
  },
};
