
import TemplateGroup from "./Templates/TemplateGroup.vue";
import TemplateDocument from "./Templates/TemplateDocument.vue";

export default {
  components: {
    TemplateGroup,
    TemplateDocument,
  },

  data() {
    return {
      helperModal: false,
    };
  },

  methods: {
    toggleHelperModal() {
      if (this.helperModal === false) {
        this.helperModal = true;
      } else {
        this.helperModal = false;
      }
    },
  },
};
