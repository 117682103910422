
import TemplateItem from "./TemplateItem.vue";

export default {
  components: {
    TemplateItem,
  },

  props: {
    document: {
      type: Object,
      default: null,
    },
  },
};
