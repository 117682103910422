import { render, staticRenderFns } from "./EditNamingKeysModal.vue?vue&type=template&id=5f48a366&scoped=true"
import script from "./EditNamingKeysModal.vue?vue&type=script&lang=js"
export * from "./EditNamingKeysModal.vue?vue&type=script&lang=js"
import style0 from "./EditNamingKeysModal.vue?vue&type=style&index=0&id=5f48a366&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f48a366",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,WidgetBlank: require('/app/components/Widget/WidgetBlank.vue').default,TextField: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/TextField/TextField.vue').default,KeyValuePair: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default,WidgetLayoutRowCellDouble: require('/app/components/Widget/WidgetLayoutRowCellDouble.vue').default,WidgetLayoutRowCellSingle: require('/app/components/Widget/WidgetLayoutRowCellSingle.vue').default,WidgetLayoutRowSpacer: require('/app/components/Widget/WidgetLayoutRowSpacer.vue').default,PushButton: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/PushButton/PushButton.vue').default,Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,WidgetLayout: require('/app/components/Widget/WidgetLayout.vue').default,Modal: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Modal/Modal.vue').default})
