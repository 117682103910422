
import NamingVariable from "./NamingVariable.vue";

export default {
  components: {
    NamingVariable,
  },

  props: {
    keys: {
      type: Array,
      required: true,
    },
  },
};
