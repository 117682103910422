import { render, staticRenderFns } from "./TemplateItem.vue?vue&type=template&id=96b51024&scoped=true"
import script from "./TemplateItem.vue?vue&type=script&lang=js"
export * from "./TemplateItem.vue?vue&type=script&lang=js"
import style0 from "./TemplateItem.vue?vue&type=style&index=0&id=96b51024&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96b51024",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KeyValuePair: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default})
