import { render, staticRenderFns } from "./CustomVariable.vue?vue&type=template&id=f13208ba&scoped=true"
import script from "./CustomVariable.vue?vue&type=script&lang=js"
export * from "./CustomVariable.vue?vue&type=script&lang=js"
import style0 from "./CustomVariable.vue?vue&type=style&index=0&id=f13208ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f13208ba",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default})
