
import AvailableKeys from "./NamingComponents/AvailableKeys.vue";
import SelectedKeys from "./NamingComponents/SelectedKeys.vue";
import DraggableTrash from "./Templates/DraggableTrash.vue";
import { ALL_DOCUMENTS, documentTypes, seperators } from "../template-data";
import { getKeysExampleString } from "../helpers/template-helpers";

export default {
  components: {
    DraggableTrash,
    AvailableKeys,
    SelectedKeys,
  },

  data() {
    return {
      allDocuments: ALL_DOCUMENTS,
      newItem: JSON.parse(JSON.stringify(this.$store.getters["templateBuilder/getNamingModalItem"])) || null,
      seperators: seperators,
    };
  },

  computed: {
    globalKeys() {
      return this.newItem.id == "FOLDER" ? documentTypes.folderKeys : documentTypes.globalKeys;
    },

    documentSpecificKeys: {
      get: function () {
        if (this.newItem === null) {
          return [];
        }
        if (this.newItem.id === "FOLDER") {
          return [];
        } else {
          return this.allDocuments.find((doc) => doc.id === this.newItem.id)?.keys || [];
        }
      },
    },

    isDraggingKey: {
      get: function () {
        return this.$store.state.templateBuilder.isDraggingKey;
      },
    },
  },

  methods: {
    getKeysExampleString,
    handleSave() {
      this.$emit("change", this.newItem.keys, this.newItem.uuid, this.newItem.separator);
    },
    handleClose() {
      this.$store.commit("templateBuilder/closeNamingModal");
    },
    appendToKeys(key) {
      return (this.newItem.keys = [...this.newItem.keys, key]);
    },
    convertIdToName(id) {
      if (id === "FOLDER") {
        return "Mappe";
      } else {
        const find = this.allDocuments.find((e) => e.id === id);
        return this.$t(find.name);
      }
    },
  },
};
