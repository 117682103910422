import { v4 as uuidv4 } from "uuid";

/**
 * Returns the corresponding natural language title for a propertyType
 *
 * Author: [CL]
 *
 * @param {PropertyType} propertyType
 * @returns {("Main property" | "Main property divided in condos" | "Condo" | "Unknown type")}
 */
export const getPropertyTypeString = function (propertyType) {
  switch (propertyType) {
    case "MAIN_PROPERTY":
      return "Hovedejendom";
    case "MAIN_PROPERTY_DIVIDED_IN_CONDOS":
      return "Hovedejendom opdelt i ejerlejligheder";
    case "CONDO":
      return "Ejerlejlighed";
    default:
      return "Unknown type";
  }
};

/**
 * Accepts a object of a seperator and an array of keys, and returns
 * a corresponding natural language string
 *
 * Author: [CL]
 *
 * @param {(Pick<TemplateDocumentItem | TemplateFolderItem, "separator" | "keys">)} element
 * @returns {*}
 */
export const getKeysExampleString = function (element) {
  const res = element.keys.reduce((acc, key) => {
    const sep = element.separator ? element.separator : "";
    if (key.id === "CUSTOM") {
      return acc + key.text + sep;
    }
    return acc + key.example + sep;
  }, "");
  return element.separator ? res.substring(0, res.length - element.separator.length) : res;
};

/**
 * Accepts a string of a Document id, "FOLDER" or "GROUP"
 * and returns a corresponding object to be stored in the template
 *
 * Author: [CL]
 *
 * @param {Pick<TemplateItemType, "id">} elem
 * @returns {TemplateItemType}
 */
export const convertToTemplateDocumentItem = function (elem) {
  const res = { keys: [{ text: "For hver ejerlejlighed" }], uuid: uuidv4(), id: elem.id };
  if (elem.id === "GROUP") {
    return {
      ...res,
      children: [],
    };
  }
  if (elem.id === "FOLDER") {
    return {
      ...res,
      children: [],
      keys: [],
      separator: " ",
    };
  }
  return {
    ...res,
    keys: [],
    separator: " ",
  };
};

/**
 * Description placeholder
 *
 * @param {Key} elem
 * @returns {*}
 */
export const addUUID = function (elem) {
  return { ...elem, uuid: uuidv4() };
};
