
import { addUUID } from "../../helpers/template-helpers.js";

export default {
  props: {
    newItemKeys: {
      type: Object,
      required: true,
    },
  },

  computed: {
    value: {
      get() {
        return this.newItemKeys?.text;
      },
      set(val) {
        this.$emit("update:newItemKeys", {
          ...this.newItemKeys,
          text: val,
        });
      },
    },
  },

  methods: {
    addUUID,
  },
};
