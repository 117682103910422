
import Draggable from "vuedraggable";
import NamingVariable from "./NamingVariable.vue";
import CustomVariable from "./CustomVariable.vue";
import { addUUID } from "../../helpers/template-helpers.js";
import AvailableSeperators from "./AvailableSeperators.vue";

export default {
  components: {
    Draggable,
    NamingVariable,
    CustomVariable,
    AvailableSeperators,
  },

  props: {
    // seperators: {
    //   type: Array,
    //   required: true,
    // },

    items: {
      type: Array,
      required: true,
    },
  },

  methods: {
    addUUID,
  },
};
