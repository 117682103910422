
import DeleteFileDownloadTemplate from "~/graphql/Template/DeleteTemplate.gql";
import Templates from "~/graphql/Template/Templates.gql";

export default {
  methods: {
    deleteTemplate() {
      this.$apollo
        .mutate({
          mutation: DeleteFileDownloadTemplate,
          variables: {
            deleteFileDownloadTemplateId: this.$route.params.id,
          },
          refetchQueries: [{ query: Templates }],
        })
        .then(() => {
          this.$router.push({
            name: "template-builder",
          });
          this.$emit("close");
        })
        .catch((error) => {
          this.$emit("close");
        });
    },
  },
};
