
import Draggable from "vuedraggable";

import TemplateGroup from "./TemplateGroup.vue";
import TemplateFolder from "./TemplateFolder.vue";
import AvailableDocumentsGroup from "./AvailableDocumentsGroup.vue";

import { convertToTemplateDocumentItem } from "../../helpers/template-helpers";
import { v4 as uuidv4 } from "uuid";
import { documentTypes } from "../../template-data";
import { Folder } from "../../template-data";

export default {
  components: {
    Draggable,
    TemplateGroup,
    TemplateFolder,
    AvailableDocumentsGroup,
  },

  props: {
    propertyType: {
      type: String,
      required: true,
    },

    template: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      folder: Folder,
      availableDocuments: documentTypes[this.propertyType],
      group: [{ id: "GROUP", children: [], uuid: uuidv4() }],
    };
  },

  methods: {
    convertToTemplateDocumentItem,
    appendToTemplate(element) {
      this.$store.commit("templateBuilder/setHasEdited", true);
      const convert = convertToTemplateDocumentItem(element);
      this.template.push(convert);
    },
  },
};
