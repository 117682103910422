
export default {
  props: {
    newItemKeys: {
      type: Object,
      required: true,
    },

    editable: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      input: "",
    };
  },

  computed: {
    value: {
      get() {
        return this.newItemKeys?.text;
      },
      set(val) {
        this.$emit("update:newItemKeys", {
          ...this.newItemKeys,
          text: val,
        });
      },
    },
  },
};
