
import TemplateItemIcon from "./TemplateItemIcon.vue";
export default {
  components: {
    TemplateItemIcon,
  },

  inheritAttrs: false,

  props: {
    multiple: {
      type: Boolean,
      required: true,
    },

    icon: {
      type: String,
      required: true,
    },
  },
};
